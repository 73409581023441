<template>
    <b-container fluid class="bulk-container py-4">
        <b-row class="mb-4">
            <b-col
                xl="9"
                class="d-flex flex-column justify-content-left flex-sm-row align-items-sm-center"
            >
                <h1 class="text-white mb-0">{{ t('bulkOrdering') }}</h1>
                <h3 class="text-white ml-3 mb-0 mt-2 mt-sm-0 pt-3">
                    {{ t('quickGuide') }}
                </h3>
            </b-col>
        </b-row>

        <b-row id="bulk-content-row">
            <b-col id="bulk-content-col">
                <b-card class="d-flex flex-column flex-grow-1 custom-font" id="bulk-card">
                    <b-container
                        fluid
                        class="flex d-flex flex-column align-items-center quick-guide"
                        id="quick-guide-container"
                    >
                        <h2>{{ t('qGuide_howToPlaceBulkOrders') }}</h2>

                        <!-- STEP 1 -->
                        <p
                            class="step-number"
                            data-step-number="1"
                            v-html="t('qGuide_bulkTabDownload')"
                        ></p>
                        <ImagesGuide imageName="1" />

                        <!-- STEP 2 -->
                        <p
                            class="step-number"
                            data-step-number="2"
                            v-html="t('qGuide_fillExcelTemplate')"
                        ></p>
                        <ImagesGuide imageName="2" />
                        <ul class="list">
                            <li v-html="t('qGuide_columnA')"></li>
                            <li v-html="t('qGuide_columnB')"></li>
                            <li v-html="t('qGuide_columnC')"></li>
                            <li v-html="t('qGuide_columnD')"></li>
                            <li v-html="t('qGuide_columnE')"></li>
                        </ul>
                        <p v-html="t('qGuide_orderLenses')"></p>
                        <p>{{ t('qGuide_saveFile') }}</p>

                        <!-- STEP 3 -->
                        <p
                            class="step-number"
                            data-step-number="3"
                            v-html="t('qGuide_clickBrowse')"
                        ></p>
                        <p class="note">{{ t('qGuide_noteUploadFile') }}</p>
                        <ImagesGuide imageName="3" />

                        <!-- STEP 4 -->
                        <p class="step-number" data-step-number="4">
                            {{ t('qGuide_selectShippingDetails') }}
                        </p>
                        <ImagesGuide imageName="4" />
                        <ul class="list">
                            <li v-html="t('qGuide_shipTo')"></li>
                            <li v-html="t('qGuide_shippingMethod')"></li>
                            <li v-html="t('qGuide_axisTolerance')"></li>
                            <li v-html="t('qGuide_poReferenceNumber')"></li>
                            <li>{{ t('qGuide_notesOptional') }}</li>
                        </ul>

                        <!-- STEP 5 -->
                        <p
                            class="step-number"
                            data-step-number="5"
                            v-html="t('qGuide_clickProcessFile')"
                        ></p>
                        <ImagesGuide imageName="5" />
                        <p v-html="t('qGuide_fileValidationProcess')"></p>
                        <ul class="list">
                            <li>{{ t('qGuide_fileValidated') }}</li>
                            <li v-html="t('qGuide_errorsFoundRedirect')"></li>
                            <li v-html="t('qGuide_noErrorsRedirect')"></li>
                        </ul>

                        <!-- STEP 6 -->
                        <p class="step-number" data-step-number="6">
                            {{ t('qGuide_correctErrors') }}
                        </p>
                        <p class="note">{{ t('qGuide_noteHover') }}</p>
                        <ImagesGuide imageName="6" />
                        <p>{{ t('qGuide_errorScreen') }}</p>
                        <ul class="list">
                            <li v-html="t('qGuide_invalidSalesCategory')"></li>
                            <li v-html="t('qGuide_invalidSphere')"></li>
                            <li v-html="t('qGuide_invalidCylinder')"></li>
                            <li v-html="t('qGuide_invalidAxis')"></li>
                            <li v-html="t('qGuide_invalidQuantity')"></li>
                        </ul>
                        <p v-html="t('qGuide_allowedValues')"></p>
                        <p class="subtitle">{{ t('qGuide_availableSphericDiopters') }}</p>
                        <p class="warning">
                            <b-icon-exclamation-triangle class="mr-2" font-scale="1.5" />{{
                                t('qGuide_disclaimer')
                            }}
                        </p>
                        <SphericTable
                            v-if="validationsSpherical"
                            :validations="validationsSpherical"
                        />
                        <p class="subtitle">{{ t('qGuide_availableToricDiopters') }}</p>
                        <p class="warning">
                            <b-icon-exclamation-triangle class="mr-2" font-scale="1.5" />{{
                                t('qGuide_disclaimer')
                            }}
                        </p>
                        <ToricTable v-if="validationsToric" :validations="validationsToric" />

                        <!-- STEP 7 -->
                        <p
                            class="step-number"
                            data-step-number="7"
                            v-html="t('qGuide_allErrorsCorrected')"
                        ></p>
                        <ImagesGuide imageName="7" />
                        <p v-html="t('qGuide_noErrorsProceed')"></p>
                        <ul class="list">
                            <li v-html="t('qGuide_passedTab')"></li>
                            <li v-html="t('qGuide_failedTab')"></li>
                        </ul>

                        <!-- STEP 8 -->
                        <p
                            class="step-number"
                            data-step-number="8"
                            v-html="t('qGuide_placeOrder')"
                        ></p>
                        <p>{{ t('qGuide_thepassedTab') }}</p>
                        <ImagesGuide imageName="8" />
                        <p>{{ t('qGuide_thefailedTab') }}</p>
                        <ImagesGuide imageName="-failed" />
                        <p v-html="t('qGuide_downloadedFile')"></p>
                        <ImagesGuide imageName="-downloaded" />

                        <!-- STEP 9 -->
                        <p
                            class="step-number"
                            data-step-number="9"
                            v-html="t('qGuide_orderConfirmation')"
                        ></p>
                        <ImagesGuide imageName="9" />

                        <!-- STEP 10 -->
                        <p
                            class="step-number"
                            data-step-number="10"
                            v-html="t('qGuide_onNavBar')"
                        ></p>
                        <ImagesGuide imageName="10" />

                        <!-- STEP 11 -->
                        <p
                            class="step-number"
                            data-step-number="11"
                            v-html="t('qGuide_fromTheOrders')"
                        ></p>
                        <p class="note">{{ t('qGuide_noteAconfirm') }}</p>
                        <ImagesGuide imageName="11" />

                        <!-- STEP 12 -->
                        <p
                            class="step-number"
                            data-step-number="12"
                            v-html="t('qGuide_boDetails')"
                        ></p>
                        <ImagesGuide imageName="12" />
                        <p v-html="t('qGuide_thePopUp')"></p>
                        <ImagesGuide imageName="-final" class="mb-5" />
                    </b-container>
                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import ImagesGuide from './components/ImagesGuide.vue';
import SphericTable from './components/SphericTable.vue';
import ToricTable from './components/ToricTable.vue';
export default {
    name: 'QuickGuide',
    components: {
        ImagesGuide,
        SphericTable,
        ToricTable,
    },
    data() {
        return {
            validationsSpherical: null,
            validationsToric: null,
        };
    },
    methods: {
        fetchValidations() {
            this.$http.get(`bulkorders/validations`).then((response) => {
                const results = response.data.results;
                if (results && results.length > 0) {
                    this.validationsStrings = results.map((validation) => {
                        const formatDiopter = (value, isCylinder = false) => {
                            if (value === null || value === undefined) {
                                return '';
                            }
                            if (value === 0) {
                                return '0.0';
                            }
                            return value % 1 === 0 && value > 0
                                ? isCylinder
                                    ? `${value.toFixed(1)}`
                                    : `+${value.toFixed(1)}`
                                : value % 1 === 0 && value < 0
                                ? `${value.toFixed(1)}`
                                : value > 0
                                ? isCylinder
                                    ? `${value}`
                                    : `+${value}`
                                : value.toString();
                        };
                        const sphericalValidations = [];
                        const toricValidations = [];
                        results.forEach((validation) => {
                            const formattedValidation = {
                                ...validation,
                                sphereDiopters: validation.sphereDiopters.map((value) =>
                                    formatDiopter(value, false)
                                ),
                                cylinderDiopters: validation.cylinderDiopters.map((value) =>
                                    formatDiopter(value, true)
                                ),
                                beginSphereDiopter: formatDiopter(
                                    validation.beginSphereDiopter,
                                    false
                                ),
                                endSphereDiopter: formatDiopter(validation.endSphereDiopter, false),
                                transitionSphereDiopter: formatDiopter(
                                    validation.transitionSphereDiopter,
                                    false
                                ),
                                beginCylinderDiopter: formatDiopter(
                                    validation.beginCylinderDiopter,
                                    true
                                ),
                                endCylinderDiopter: formatDiopter(
                                    validation.endCylinderDiopter,
                                    true
                                ),
                            };
                            if (validation.lensTypeCode === 'ICL') {
                                sphericalValidations.push(formattedValidation);
                            } else if (validation.lensTypeCode === 'TICL') {
                                toricValidations.push(formattedValidation);
                            }
                        });
                        this.validationsSpherical = sphericalValidations;
                        this.validationsToric = toricValidations;
                    });
                }
            });
        },
        observeSteps() {
            const options = {
                root: null,
                threshold: 0.1,
            };
            const observer = new IntersectionObserver((entries, observer) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('in-view');
                    } else {
                        entry.target.classList.remove('in-view');
                    }
                });
            }, options);
            const steps = document.querySelectorAll('.step-number');
            steps.forEach((step) => {
                observer.observe(step);
            });
        },
    },
    created() {
        this.fetchValidations();
    },
    mounted() {
        this.observeSteps();
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';
.custom-font {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.warning {
    background-color: $warning;
    padding: 0.5em 1em;
    border-radius: 0.5em;
    font-size: 0.8em;
    margin-top: 0px;
    margin-bottom: 1em;
}
.subtitle {
    color: $primary;
    font-weight: bold;
    font-size: larger;
}
.note {
    padding-left: 70px;
    font-size: 0.8em;
}
.list {
    padding-left: 80px;
    width: 100%;
    text-align: left;
}
.list li {
    margin-bottom: 0.5em;
}
.bulk-container {
    min-height: 92vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    background-color: $primary;
    font-size: large;
}
.quick-guide {
    line-height: 1.4;
    width: 80%;
    max-width: 1000px;
}
h2 {
    font-size: 2.5em;
    color: $primary;
    margin-top: 1em;
}
p {
    width: 100%;
    text-align: left;
    margin: 0.5em 0;
}
.step-number::before {
    content: attr(data-step-number);
    position: absolute;
    left: 0;
    top: 50%;
    padding-bottom: 4px;
    background-color: rgba(252, 178, 129, 0);
    color: white;
    border-radius: 50%;
    font-size: 1.2em;
    width: 1.7em;
    height: 1.7em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 2s ease-in-out;
}
.step-number {
    margin-top: 2em;
    border-top: 2px solid transparent;
    position: relative;
    padding-left: 3em;
    padding-top: 2em;
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 1px;
        height: 2px;
        background-color: $primary;
        transition: width 0.5s ease-in-out;
    }
    &.in-view::after {
        width: 100%;
    }
}
.step-number.in-view::before {
    background-color: rgba(252, 178, 129, 1);
}
::v-deep .table td,
::v-deep .table th {
    font-size: 0.7em;
}
::v-deep .table th {
    background-color: white !important;
    color: black !important;
}
::v-deep .diopters-table {
    height: 400px;
}
::v-deep .qcard {
    border-top: 1px lightgray solid;
}
</style>
